(function($) {
    $(document).ready(function() {
        var messageSelector = '.grp-messagelist li';
        var closeSelector = '.message-close';
        var closeAllSelector = '.grp-message-close-all';
        $.fn.messageClose = function() {
            $(this).fadeTo('fast', 0, function() {
                $(this).hide('fast', function() {
                    $(this).remove();
                });
            });
        };
        $.fn.messageCloseTimeout = function(interval) {
            var $this = $(this);
            setTimeout(function() {
                $this.messageClose();
                var $close = $this.find(closeSelector);
                if ($close.attr('data-url')) {
                    $.ajax({
                        url: $close.attr('data-url')
                    });
                }
            }, interval);
        };
        $(closeSelector).click(function(event) {
            var $this = $(this);
            event.preventDefault();
            if ($this.attr('data-url')) {
                $.ajax({
                    url: $this.attr('data-url')
                });
            }
            if ($(messageSelector).length <= 2) {
                $(closeAllSelector).messageClose();
            }
            $this.closest(messageSelector).messageClose();
        });
        $(closeAllSelector).click(function(event) {
            var $this = $(this);
            event.preventDefault();
            $.ajax({
                url: $this.attr('data-url')
            });
            $this.messageClose();
            $(messageSelector).messageClose();
        });
    });
})(django.jQuery);