(function($) {

    var inputTypes = [
        "[type='search']",
        "[type='email']",
        "[type='url']",
        "[type='tel']",
        "[type='number']",
        "[type='range']",
        "[type='date']",
        "[type='month']",
        "[type='week']",
        "[type='time']",
        "[type='datetime']",
        "[type='datetime-local']"].join(",");

    grappelli.cleanInputTypes = function() {
        $("form").each(function(){
            $(this).find(':input').filter(inputTypes).each(function(){
                $(this).attr("type", "text");
            });
        });
    };

})(grp.jQuery);
