/**
 * Add class="grp-table" to the <table> element in the filebrowser changelist view
 *
 * django-filebrowser fixed this in https://github.com/sehmaschine/django-filebrowser/commit/46d8b0
 * but only included the fix in the 3.10.1 release, which requires Django 2.0
 * and django-grappelli 2.11.x. Copying and overriding filebrowser/index.html
 * in order to add class="grp-table" is a hot mess. Adding it with javascript
 * is actually the least obtrusive way to make the filebrowser look correct.
 */
(function() {
    document.addEventListener('DOMContentLoaded', function() {
        if (!document.body.classList.contains('grp-filebrowser')) {
            return;
        }
        var changelist = document.getElementById('grp-changelist');
        if (!changelist) {
            return;
        }
        Array.from(changelist.querySelectorAll('table').forEach(function(table) {
            table.classList.add('grp-table');
        }));
    });
}());
